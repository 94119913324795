import { defineStore } from "pinia";

export const useSearchResultsStore = defineStore("SearchResultsStore", {
  state: () => {
    return {
      searchResults: [],
      highLightLayer: {},
      highLightLayerCount: 0,
      configs: {},
      gridDetailId: 0,
      gridDetailResults: [],
      structureDetailsId: 0,
      structureDetailsResults: [],
      maintGridResults: [],
      defaultLayerStructureCount: 0,
      queryThreasholdExceeded: false,
      filterThreasholdExceeded: false,
      advancedPayload: undefined,
      maxStructuresAllowed: 0,
      maxFilteredStructuresAllowed: 0,
      reRunFilterQueries: false,
      metaDataExists: false,
      ApplyButtonDisableonThresholdExceeded: false,
      solutionApprovalResults: [],
      selectedStructureFromGrid: null
    };
  },
  getters: {
    getHighLightLayerCount() {
      return this.highLightLayerCount;
    },
    getSearchResults() {
      return this.searchResults;
    },
    getHighLightLayer() {
      return this.highLightLayer;
    },
    getConfigurations() {
      return this.configs;
    },
    getUserProjectConfiguration() {
      return this.configs?.userProjectConfig;
    },
    getProjectApplicationConfigProxy() {
      return this.configs?.projectApplicationConfig;
    },
    getMVAdvancedSearches() {
      return this.configs.projectApplicationConfig.MVAdvancedSearch;
    },
    getMapCtl() {
      return this.configs.o360MapRef?.mapCtl;
    },
    getGridDetailId() {
      return this.gridDetailId;
    },
    getGridDetailResults() {
      return this.gridDetailResults;
    },
    getStructureDetailsId() {
      return this.structureDetailsId;
    },
    getStructureDetailsResults() {
      return this.structureDetailsResults;
    },
    getMaintGridResults() {
      return this.maintGridResults;
    },
    getSolutionApprovalGridResults() {
      return this.solutionApprovalResults;
    },
    getDefaultLayerStructureCount() {
      return this.defaultLayerStructureCount;
    },
    getQueryThreasholdExceeded() {
      console.log("getting query threashould exceeded");
      return this.queryThreasholdExceeded;
    },
    getFilterThreasholdExceeded() {
      console.log("getting filter threashould exceeded");
      return this.filterThreasholdExceeded;
    },
    getAdvancedPayload() {
      console.log("getting advanced payload");
      return this.advancedPayload;
    },
    getMaxStructuresAllowed() {
      return this.maxStructuresAllowed;
    },
    getMaxFilteredStructuresAllowed() {
      return this.maxFilteredStructuresAllowed;
    },
    getReRunFilterQueries() {
      console.log("getReRunFilterQueries");
      return this.reRunFilterQueries;
    },
    getMetaDataExists() {
      console.log("getting meta data exists");
      console.log(this.metaDataExists);
      return this.metaDataExists;
    },
    getApplyButtonDisableonThresholdExceeded() {
      return this.ApplyButtonDisableonThresholdExceeded;
    },
    getSelectedStructureFromGrid() {
      return this.selectedStructureFromGrid;
    }
  },
  actions: {
    setHighLightLayerCount(highLightLayerCount) {
      this.highLightLayerCount = highLightLayerCount;
    },
    setSearchResults(searchResults) {
      console.log("setting search results");
      console.log(searchResults);
      this.searchResults = searchResults;
    },
    appendSearchResults(searchResults) {
      console.log("appending search results");
      console.log(searchResults);

      const searchResultsCopy = JSON.parse(JSON.stringify(this.searchResults));
      for (const searchResult of searchResults) {
        const featureIndex = searchResultsCopy.findIndex(
          sr => sr.LocationID === searchResult.LocationID
        );
        if (featureIndex === -1) {
          // if structure doesn't exist in current result add it
          searchResultsCopy.push(searchResult);
        } else {
          // if structure exist in current result remove it (polygon selection desired behavior)
          searchResultsCopy.splice(featureIndex, 1);
        }
      }

      this.searchResults = searchResultsCopy;
    },
    initSearchResults() {
      this.searchResults = [];
    },
    setHighLightLayer(highLightLayer) {
      this.highLightLayer = highLightLayer;
    },
    async setConfigurations(configs) {
      this.configs = configs;
    },
    setCurrentProjectConfig(projectConfig) {
      this.configs.projectConfig = projectConfig;
    },
    setStoreConfigProperty(store) {
      this.configs.store = store;
    },
    setUserProjectConfigs(userProjectConfig) {
      this.configs.userProjectConfig = userProjectConfig;
    },
    setGridDetailId(gridDetailId) {
      this.gridDetailId = gridDetailId;
    },
    setGridDetailResults(gridDetailResults) {
      this.gridDetailResults = gridDetailResults;
    },

    setStructureDetailsId(structureDetailsId) {
      this.structureDetailsId = structureDetailsId;
    },
    setStructureDetailsResults(structureDetailsResults) {
      this.structureDetailsResults = structureDetailsResults;
    },
    setMaintenanceLayer(maintenanceLayer) {
      this.maintenanceLayer = maintenanceLayer;
    },
    setMaintenanceGridSearchResults(maintGridResults) {
      this.maintGridResults = maintGridResults;
    },
    appendMaintenanceGridSearchResults(maintGridResults) {
      const searchResultsCopy = JSON.parse(
        JSON.stringify(this.maintGridResults)
      );

      for (const maintGridResult of maintGridResults) {
        const featureIndex = searchResultsCopy.findIndex(
          sr => sr.MaintenanceID === maintGridResult.MaintenanceID
        );
        if (featureIndex === -1) {
          // if structure doesn't exist in current result add it
          searchResultsCopy.push(maintGridResult);
        } else {
          // if structure exist in current result remove it (polygon selection desired behavior)
          searchResultsCopy.splice(featureIndex, 1);
        }
      }

      this.maintGridResults = searchResultsCopy;
      // this.maintGridResults = this.maintGridResults.concat(maintGridResults);
    },
    setSolutionApprovalGridSearchResults(solutionApprovalGridResults) {
      this.solutionApprovalResults = solutionApprovalGridResults;
    },
    appendSolutionApprovalGridSearchResults(solutionApprovalGridResults) {
      const searchResultsCopy = [...this.solutionApprovalResults];

      for (const solutionApprovalGridResult of solutionApprovalGridResults) {
        const featureIndex = this.solutionApprovalResults.findIndex(
          sr =>
            sr["Structure Number"] ===
            solutionApprovalGridResult["Structure Number"]
        );
        console.log(featureIndex);
        if (featureIndex === -1) {
          // if structure doesn't exist in current result add it
          searchResultsCopy.push(solutionApprovalGridResult);
        }
      }

      this.solutionApprovalResults = searchResultsCopy;
    },
    setDefaultLayerStructureCount(defaultLayerStructureCount) {
      this.defaultLayerStructureCount = defaultLayerStructureCount;
    },
    setQueryTheasholdExceeded(queryThreasholdExceeded) {
      console.log("Setting Query Threashold exceeded");
      this.queryThreasholdExceeded = queryThreasholdExceeded;
    },
    setFilterTheasholdExceeded(filterThreasholdExceeded) {
      console.log("Setting Filter Threashold exceeded");
      this.filterThreasholdExceeded = filterThreasholdExceeded;
    },
    setAdvancedPayload(advancedPayload) {
      console.log("setting advancedPayload");
      this.advancedPayload = advancedPayload;
    },
    setMaxStructuresAllowed(maxStructuresAllowed) {
      this.maxStructuresAllowed = maxStructuresAllowed;
    },
    setMaxFilteredStructuresAllowed(maxFilteredStructuresAllowed) {
      this.maxFilteredStructuresAllowed = maxFilteredStructuresAllowed;
      console.log("setMaxFilteredStructuresAllowed");
      console.log(maxFilteredStructuresAllowed);
      console.log(this.maxFilteredSturcturesAllowed);
    },
    setReRunFilterQueries(reRunFilterQueries) {
      console.log("setting reRunFilterQueries");
      this.reRunFilterQueries = reRunFilterQueries;
    },
    setMetaDataExists(metaDataExists) {
      console.log("setting metaDataExists");
      console.log(metaDataExists);
      this.metaDataExists = metaDataExists;
    },
    setApplyButtonDisableonThresholdExceeded(
      applyButtonDisableonThresholdExceeded
    ) {
      this.ApplyButtonDisableonThresholdExceeded =
        applyButtonDisableonThresholdExceeded;
    },
    setSelectedStructureFromGrid(selectedStructureFromGrid) {
      this.selectedStructureFromGrid = selectedStructureFromGrid;
    }
  }
});

// methods
// get authorizationObj
// get customers and projects for user
// get applications for project
// get capabilities for applications getCapabilities(project,applicationName);

// project initialization
// for each project - add navigation item
// when application is selected
// application will ask for capabilities and
// it will become the active application in the router view

// data stores
// customer/projects
// projects/applications
// applications/capabilitiesimport Vue from "vue";

// import * as Msal from "msal";
// import { createPinia } from "pinia";
// const pinia = createPinia();
import authorizationManager from "@/Lib/AuthorizationManager.js";
import configurationManager from "@/Lib/ConfigurationManager.js";

import Vue from "vue";
import CosmosQueryEngine from "@/components/dynamic/MapViewV2/LocalAPI/CosmosQueryEnginePGM";
import payloadManager from "@/Lib/Database/payloadManager";
//import { O360CosmosDataSource } from "@/Lib/SharedComponents/mapControl/cosmosAPI/O360CosmosDataSource";
import { O360AdvancedSearchClient } from "@/Lib/SharedComponents/mapControl/cosmosAPI/O360AdvancedSearchClient";
import { O360MapControlDataSource } from "@/Lib/SharedComponents/mapControl/cosmosAPI/O360MapControlDataSource";
import { O360MapViewPayloadManager } from "../../../src/components/dynamic/MapViewV2/Lib/Payload/O360MapViewPayloadManager";
import { O360StoreHelper } from "../../Lib/SharedComponents/mapControl/cosmosAPI/O360StoreHelper";

export const state = {
  MSALObj: null,
  O360APIScope: null,
  ClientId: null,
  Authority: null,
  AuthToken: null,
  AuthTokenExpiresOn: null,
  AuthTokenExpChkProcessId: null,
  authAccountObj: null,
  OnAppStartState: null,
  OnAppStartRoute: null,
  UserName: null,
  UserId: null,
  UserEmail: null,
  AppRegistry: null,
  AppRegistryURL: null,
  UserAuthObject: null,
  UserAuthObjURL: null,
  UserApplications: null,
  FindIfUserExistURL: null,
  AddNewUserURL: null,
  currentProject: null,
  CustomerProjectURL: null,
  Capabilities: null,
  CurrentCustProjectConfig: null,
  UserFilterConfiguration: null,
  ProjectMVSearchConfiguration: null,
  projects: [],
  hasUserTakenTour: null,
  retakeTour: false,
  UserInfo: null,
  UserInfoURL: null,
  DefaultProjectUpdateURL: null,
  AllUserTypes: null,
  AllUserTypesURL: null,
  UserPreferences: null,
  UserPreferencesURL: null,
  ServiceTypeArr: [],
  MappingServiceToName: {},
  showUserChangelog: null,
  reShowChangelog: false,
  dbdContact: {},
  StatesMapping: null,
  IsAdvancedSearchFormValid: false,
  CurrentAdvancedQuery: null,
  CurrentMVUserFilterQuery: null,
  AttributeValues: {}
};
export const mutations = {
  SET_MY_MSAL_OBJ(state, mSALObj) {
    state.MSALObj = mSALObj;
  },
  SET_O360API_SCOPE(state, o360APIScope) {
    state.O360APIScope = o360APIScope;
  },
  SET_CLIENT_ID(state, clientId) {
    state.ClientId = clientId;
  },
  SET_AUTHORITY(state, authority) {
    state.Authority = authority;
  },
  SET_AUTHTOKEN(state, response) {
    state.AuthToken = response.accessToken;
    state.AuthTokenExpiresOn = response.expiresOn;
    state.AuthTokenExpChkProcessId = response.expChkProcessId;
  },
  SET_AUTH_ACCOUNT_OBJ(state, authAccountObj) {
    state.authAccountObj = authAccountObj;
  },
  SET_ON_START_APP_STATE(state, onAppStartState) {
    state.OnAppStartState = onAppStartState;
  },
  SET_ON_START_APP_ROUTE(state, onAppStartRoute) {
    state.OnAppStartRoute = onAppStartRoute;
  },
  SET_USERNAME(state, userName) {
    state.UserName = userName;
  },
  SET_USERID(state, userName) {
    state.UserId = userName;
  },
  SET_USEREMAIL(state, userEmail) {
    state.UserEmail = userEmail;
  },
  SET_USERFNAME(state, userFName) {
    state.UserInfo.FirstName = userFName;
  },
  SET_USERLNAME(state, userLName) {
    state.UserInfo.LastName = userLName;
  },
  SET_USERZIP(state, userZip) {
    state.UserInfo.ZipCode = userZip;
  },
  SET_USERCITY(state, userCity) {
    state.UserInfo.City = userCity;
  },
  SET_USERSTATE(state, userState) {
    state.UserInfo.State = userState;
  },
  SET_USERADDRESS(state, userAddress) {
    state.UserInfo.Address = userAddress;
  },
  SET_APP_REGISTRY(state, appRegistry) {
    state.AppRegistry = appRegistry;
  },
  SET_APP_REGISTRY_URL(state, appRegistryURL) {
    state.AppRegistryURL = appRegistryURL;
  },
  SET_USER_AUTH_OBJECT(state, userAuthObject) {
    state.UserAuthObject = userAuthObject;
    // reconcile here with auth manager
  },
  SET_USER_AUTH_OBJECT_URL(state, userAuthObjectURL) {
    state.UserAuthObjURL = userAuthObjectURL;
    // reconcile here with auth manager
  },
  SET_USER_APPLICATIONS(state, userApplications) {
    state.UserApplications = userApplications;
  },
  SET_FIND_IF_USER_EXIST_URL(state, findIfUserExistURL) {
    state.FindIfUserExistURL = findIfUserExistURL;
  },
  SET_ADD_NEW_USER_URL(state, addNewUserURL) {
    state.AddNewUserURL = addNewUserURL;
  },
  SET_APP_CAPABILITIES(state, capabilities) {
    state.Capabilities = capabilities;
  },
  SET_CUSTOMER_PROJECT_URL(state, customerProjectURL) {
    state.CustomerProjectURL = customerProjectURL;
  },
  SET_CURRENT_CUST_PROJECT_CONFIG(state, project) {
    state.CurrentCustProjectConfig = project;
  },
  SET_USER_FILTER_CONFIGURATION(state, userFilterConfiguration) {
    state.UserFilterConfiguration = userFilterConfiguration;
  },
  SET_PROJECT_MV_SEARCH_CONFIGURATION(state, projectMVSearchConfiguration) {
    state.ProjectMVSearchConfiguration = projectMVSearchConfiguration;
  },
  SET_CURRENT_PROJECT(state, project) {
    state.currentProject = project;
  },
  SET_PROJECTS(state, projects) {
    state.projects = projects;
  },
  SET_HAS_USER_TAKEN_TOUR(state, hasTakenTour) {
    state.hasUserTakenTour = hasTakenTour;
  },
  SET_RETAKE_TOUR(state) {
    if (state.retakeTour) {
      state.retakeTour = false;
    } else {
      state.retakeTour = true;
    }
  },
  SET_USER_INFO(state, userInfo) {
    state.UserInfo = userInfo;
  },
  SET_USER_INFO_URL(state, userInfoURL) {
    state.UserInfoURL = userInfoURL;
  },
  SET_DEFAULT_PROJECCT_UPDATE_URL(state, defaultProjectUpdateURL) {
    state.DefaultProjectUpdateURL = defaultProjectUpdateURL;
  },
  SET_ALL_USER_TYPES(state, allUserTypes) {
    state.AllUserTypes = allUserTypes;
  },
  SET_ALL_USER_TYPES_URL(state, allUserTypesURL) {
    state.AllUserTypesURL = allUserTypesURL;
  },
  SET_USER_PREFERENCES(state, userPreferences) {
    state.UserPreferences = userPreferences;
  },
  SET_USER_PREFERENCES_URL(state, userPreferencesURL) {
    state.UserPreferencesURL = userPreferencesURL;
  },
  SET_SERVICE_TYPE_ARR(state, value) {
    state.ServiceTypeArr = value;
  },
  SET_MAPPING_SERVICE_TO_NAME(state, value) {
    state.MappingServiceToName = value;
  },
  SET_SHOW_USER_CHANGELOG(state, value) {
    state.showUserChangelog = value;
  },
  SET_RE_SHOW_CHANGELOG(state, value) {
    state.reShowChangelog = value;
  },
  SET_DBD_CONTACT(state, value) {
    state.dbdContact = value;
  },
  SET_STATES_MAPPING(state, data) {
    state.StatesMapping = data;
  },
  SET_ADVANCED_SEARCH_VALIDITY(state, payload) {
    state.IsAdvancedSearchFormValid = payload;
  },
  SET_CURRENT_ADVANCED_QUERY(state, payload) {
    Vue.set(state, "CurrentAdvancedQuery", payload);
  },
  SET_MVUser_FILTER_QUERY(state, payload) {
    Vue.set(state, "CurrentMVUserFilterQuery", payload);
  },
  SET_MVUser_Filter_HAS_CHANGES(state, payload) {
    Vue.set(state, "CurrentMVUserFilterHasChanges", payload);
  },
  SET_ATTR_VALUE(state, payload) {
    Vue.set(state.AttributeValues, payload.name, {
      values: payload.values,
      maxExceeded: payload.maxExceeded
    });
  },
  CLEAR_ATTR_VALUE(state) {
    state.AttributeValues = {};
  }
};
export const getters = {
  advanceSearchAttributeValues: state => {
    return state.AttributeValues;
  },
  authAccountObj: state => {
    return state.authAccountObj;
  },
  getMappingService: state => {
    return state.MappingServiceToName;
  },
  getServiceTypeDescription: state => key => {
    return state.MappingServiceToName[key];
  },
  IsAdvancedSearchFormValid: state => {
    return state.IsAdvancedSearchFormValid;
  },
  filteredJobNumbersResiliencyEnabled() {
    // return a comma delimited list of jobNumbers that is associated with one of the properties passed
    if (!state.CurrentCustProjectConfig) {
      return null;
    } else {
      const result = state.CurrentCustProjectConfig.JobIds.filter(a => {
        if (a.IsResiliencyEnabled) {
          return true;
        } else {
          return false;
        }
      })
        .map(a => a.JobNumber)
        .join(",");
      return result;
    }
  },
  // used in PD
  filteredJobNumbers2: state => (years, serviceTypes) => {
    // return a comma delimited list of jobNumbers that is associated with one of the properties passed
    if (!state.CurrentCustProjectConfig) {
      return null;
    } else {
      const result = state.CurrentCustProjectConfig.JobIds.filter(a => {
        if (years && serviceTypes && years.length && serviceTypes.length) {
          if (
            years.includes(a.ContractYear) &&
            serviceTypes.includes(a.ServiceType)
          ) {
            return true;
          }
        } else {
          return false;
        }
      })
        .map(a => a.JobNumber)
        .join(",");
      return result;
    }
  },
  // used in PD
  filteredJobNumbers3: state => (years, serviceTypes, companies) => {
    // return a comma delimited list of jobNumbers that is associated with one of the properties passed
    if (!state.CurrentCustProjectConfig) {
      return null;
    }
    const isRollup = state.CurrentCustProjectConfig.IsRollUpProject;
    const result = state.CurrentCustProjectConfig.JobIds.filter(a => {
      if (
        years &&
        serviceTypes &&
        serviceTypes.length &&
        (!isRollup || (companies && companies.length))
      ) {
        for (let j = 0; j < serviceTypes.length; j++) {
          if (
            years.includes(a.ContractYear) &&
            a.ServiceType == serviceTypes[j] &&
            (!isRollup || companies.find(pid => pid == a.ProjectId))
          ) {
            return true;
          }
        }
      } else {
        return false;
      }
    })
      .map(a => a.JobNumber)
      .join(",");
    return result;
  },
  // used in MV
  filteredJobNumbers4: (state, getters) => years => {
    // return a comma delimited list of jobNumbers that is associated with one of the properties passed
    if (
      state.CurrentCustProjectConfig == undefined ||
      state.CurrentCustProjectConfig == null
    ) {
      return null;
    } else {
      const result = getters.userPreFilterfilteredJobNumbers
        .filter(a => {
          if (years && years.length) {
            for (let i = 0; i < years.length; i++) {
              if (a.ContractYear == years[i]) {
                return true;
              }
            }
          } else {
            return false;
          }
        })
        .map(a => a.JobNumber)
        .join(",");
      return result;
    }
  },
  // Used in MV
  filteredJobNumbers5: (state, getters) => (years, serviceTypes, newMap) => {
    // return a comma delimited list of jobNumbers that is associated with one of the properties passed
    if (
      state.CurrentCustProjectConfig == undefined ||
      state.CurrentCustProjectConfig == null
    ) {
      return null;
    } else {
      const filteredJobNumbers = newMap
        ? getters.userPreFilterfilteredJobNumbers2
        : getters.userPreFilterfilteredJobNumbers;
      const result = filteredJobNumbers
        .filter(a => {
          if (years && serviceTypes && years.length && serviceTypes.length) {
            for (let i = 0; i < years.length; i++) {
              for (let j = 0; j < serviceTypes.length; j++) {
                if (
                  a.ContractYear == years[i] &&
                  a.ServiceType == serviceTypes[j]
                ) {
                  return true;
                }
              }
            }
          } else if (years && years.length) {
            for (let i = 0; i < years.length; i++) {
              if (a.ContractYear == years[i]) {
                return true;
              }
            }
          } else if (serviceTypes && serviceTypes.length) {
            for (let i = 0; i < serviceTypes.length; i++) {
              if (a.ServiceType == serviceTypes[i]) {
                return true;
              }
            }
          } else {
            return true;
          }
        })
        .map(a => a.JobNumber)
        .join(",");
      // console.log("Job Numbers selected: ", result);
      if (result) {
        return result;
      } else {
        return "NO_JOB_NUMBER_MATCH";
      }
    }
  },
  //Used in PD
  filteredJobNumbersWithRollupProjectId:
    state => (years, serviceTypes, companies) => {
      // return a comma delimited list of jobNumbers with their projectIds for a rollup
      if (
        !state.CurrentCustProjectConfig ||
        !years ||
        !serviceTypes ||
        !serviceTypes.length ||
        !companies ||
        !companies.length
      ) {
        return "";
      }

      const jobs = new Set();
      let result = "";
      for (const a of state.CurrentCustProjectConfig.JobIds) {
        for (let j = 0; j < serviceTypes.length; j++) {
          if (
            years.includes(a.ContractYear) &&
            a.ServiceType == serviceTypes[j] &&
            !jobs.has(a.JobNumber) &&
            companies.find(pid => pid == a.ProjectId)
          ) {
            jobs.add(a.JobNumber);
            if (result != "") result += ",";
            result = `${result}${a.ProjectId}:${a.JobNumber}`;
          }
        }
      }
      return result;
    },
  // filters projects JobIds object with user's pre configured filter
  userPreFilterfilteredJobNumbers: state => {
    if (state.CurrentCustProjectConfig) {
      // console.log(
      //   "userPreFilter jobNumbers: ",
      //   state.UserFilterConfiguration
      //     ? state.UserFilterConfiguration[0].JobNumbers
      //     : state.UserFilterConfiguration
      // );
      const filteredJobNumbers = state.CurrentCustProjectConfig.JobIds.filter(
        a => {
          if (
            !Array.isArray(state.UserFilterConfiguration) ||
            !state.UserFilterConfiguration[0].JobNumbers ||
            (Array.isArray(state.UserFilterConfiguration) &&
              state.UserFilterConfiguration[0].JobNumbers.includes(a.JobNumber))
          ) {
            return true;
          } else {
            return false;
          }
        }
      );
      // console.log("jobNumbers after userPreFilter: ", filteredJobNumbers);
      return filteredJobNumbers;
    }
    return [];
  },

  userPreFilterfilteredJobNumbers2() {
    const o360StoreHelper = new O360StoreHelper();
    const configs = o360StoreHelper.getConfigurationsProxy();
    if (configs?.projectConfig) {
      // console.log(
      //   "userPreFilter jobNumbers: ",
      //   state.UserFilterConfiguration
      //     ? state.UserFilterConfiguration[0].JobNumbers
      //     : state.UserFilterConfiguration
      // );
      const userProjectConfig = configs?.userProjectConfig;
      const userProjectConfigJobNumbers =
        userProjectConfig?.[0]?.MapView?.[0].JobNumbers;
      const filteredJobNumbers = configs?.projectConfig.JobIds.filter(a => {
        if (
          !Array.isArray(userProjectConfig) ||
          !userProjectConfigJobNumbers ||
          userProjectConfigJobNumbers.includes(a.JobNumber)
        ) {
          return true;
        } else {
          return false;
        }
      });
      // console.log("jobNumbers after userPreFilter: ", filteredJobNumbers);
      return filteredJobNumbers;
    }
    return [];
  },
  mSALObj: state => {
    return state.MSALObj;
  },
  o360APIScope: state => {
    return state.O360APIScope;
  },
  debug: state => {
    if (
      state.authAccountObj &&
      state.authAccountObj.idTokenClaims &&
      state.authAccountObj.idTokenClaims.jobTitle == "DEBUG"
    ) {
      return true;
    }
    return false;
  },
  isCustomer: state => {
    if (state.authAccountObj) {
      // console.log("inside isCustomer: ", state.authAccountObj);
      return state.authAccountObj.idTokenClaims.idp ? false : true;
    }
    return false;
  },
  getCustomerList: state => {
    return state.UserAuthObject ? state.UserAuthObject.customers : undefined;
  },
  clientId: state => {
    return state.ClientId;
  },
  hasUserTakenTour: state => {
    return state.hasUserTakenTour;
  },
  retakeTour: state => {
    return state.retakeTour;
  },
  authority: state => {
    return state.Authority;
  },
  authToken: state => {
    return state.AuthToken;
  },
  authTokenExpiresOn: state => {
    return state.AuthTokenExpiresOn;
  },
  authTokenExpirationCheckProcessId: state => {
    return state.AuthTokenExpChkProcessId;
  },
  onStartAppState: state => {
    return state.OnAppStartState;
  },
  onStartAppRoute: state => {
    return state.OnAppStartRoute;
  },
  userName: state => {
    return state.UserName;
  },
  userId: state => {
    return state.UserId;
  },
  userEmailFromB2C: state => {
    return state.UserEmail;
  },
  userInfo: state => {
    if (state.UserInfo) return state.UserInfo;
  },
  userEmail: state => {
    if (state.UserInfo) return state.UserInfo.EmailAddress;
    return "";
  },
  userFName: state => {
    if (state.UserInfo) return state.UserInfo.FirstName;
    return "";
  },
  userLName: state => {
    if (state.UserInfo) return state.UserInfo.LastName;
    return "";
  },
  userCity: state => {
    if (state.UserInfo) return state.UserInfo.City;
    return "";
  },
  userState: state => {
    if (state.UserInfo) return state.UserInfo.State;
    return "";
  },
  userZipCode: state => {
    if (state.UserInfo) return state.UserInfo.ZipCode;
    return "";
  },
  userAddress: state => {
    if (state.UserInfo) return state.UserInfo.Address;
    return "";
  },
  userTitle: state => {
    if (state.UserInfo) return state.UserInfo.Title;
    return "";
  },
  userPhoneNumber: state => {
    if (state.UserInfo) return state.UserInfo.Phone;
    return "";
  },
  userCompanyName: state => {
    if (state.UserInfo) return state.UserInfo.UserPreferences.Company;
    return "";
  },
  userType: state => {
    if (state.UserInfo) return state.UserInfo.UserPreferences.UserType;
    return "";
  },
  customerId: state => {
    if (state.UserInfo) return state.UserInfo.UserPreferences.CustomerId;
    return "";
  },
  appRegistry: state => {
    return state.AppRegistry;
  },
  appRegistryURL: state => {
    return state.AppRegistryURL;
  },
  userAuthObject: state => {
    return state.UserAuthObject;
  },
  userApplications: state => {
    // console.dir(state.UserApplications);
    return state.UserApplications;
  },
  findIfUserExistURL: state => {
    return state.FindIfUserExistURL;
  },
  addNewUserURL: state => {
    return state.AddNewUserURL;
  },
  allCapabilities: state => {
    return state.Capabilities;
  },
  capabilities: state => appName => {
    return state.Capabilities?.[appName];
  },
  getFilteredWorkRequestStatuses: (_state, _getters, rootState) => {
    const workRequests = _getters.getWorkRequestStatuses;
    const updatedRequests = workRequests.filter(request =>
      request.ShowToggle == false ||
      (request.ShowToggle == true && request.ToggleDefaultValue == true)
        ? true
        : false
    );

    return updatedRequests;
  },
  getWorkRequestStatuses: (_state, _getters, rootState) => {
    return rootState?.JointUseStore?.projectDoAuth?.WorkRequestStatuses || [];
  },
  customerProjectURL: state => {
    return state.CustomerProjectURL;
  },
  userCustomersLength: state => {
    if (state.UserAuthObject == null) {
      return null;
    }
    return state.UserAuthObject.customers.length;
  },
  currentCustProjectConfig: state => {
    return state.CurrentCustProjectConfig;
  },
  getQueryBuilderSchemas:
    (state, getters, rootState, rootGetters) => (schema,pgmVisibility) => {
      const advancedSearchVisibility = [];
      const visibility = pgmVisibility || getters.currentCustProjectConfig.Visibility.Schema;

      // get distinct list of table names for profit centers.
      const tables = [
        ...new Set(
          Object.values(
            schema || getters.currentCustProjectConfig?.ProfitCenterVisibility
          ).flatMap(t => t)
        )
      ].map(t => {
        t = t.toUpperCase();
        if (t !== "OUS_LOCATION") {
          if (t === "OUS_INSPECTION" || t === "OUS_STRUCTURE") {
            t = "OUS_LOCATION." + t;
          } else if (
            t.toLowerCase().includes("leg") &&
            t.toUpperCase() != "OUS_LEGCONDITION"
          )
            t = "OUS_LOCATION.OUS_INSPECTION.OUS_LEGCONDITION." + t;
          else {
            t = "OUS_LOCATION.OUS_INSPECTION." + t;
          }
        }
        return t;
      });

      console.log("SCHEMA", schema);
      console.log("TABLES", tables);
      for (const table of tables) {
        for (const col of Object.keys(visibility[table] || {})) {
          const column = visibility[table][col];

          if (column.MapViewEnabled) {
            const inputType = convertToInputType(
              column.DataType,
              column.SQLDataType
            );
            advancedSearchVisibility.push({
              ColumnName: column.ColumnName,
              TableName: column.TableName,
              SQLDataType: column.SQLDataType,
              DataType: column.DataType,
              inputType,
              type: column.SQLDataType,
              id: `${column.TableName}.${column.ColumnName}`,
              code: `${column.TableName}.${column.ColumnName}`,
              label: getDisplayName(
                column.TableName,
                column.ColumnName,
                column.DisplayName
              ),
              operators:
                column.DataType !== "boolean"
                  ? getConditionsByType(inputType)
                  : [],
              ...(column.DataType === "boolean" && {
                choices: [
                  { label: "True", value: "true" },
                  { label: "False", value: "false" }
                ]
              })
            });
          }
        }
      }
      return advancedSearchVisibility
        .filter(
          (value, index, self) =>
            index ===
            self.findIndex(
              t =>
                t.ColumnName === value.ColumnName &&
                t.TableName === value.TableName
            )
        )
        .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0));
    },
  userFilterConfiguration: state => {
    return state.UserFilterConfiguration;
  },
  projectMVSearchConfiguration: state => {
    return state.ProjectMVSearchConfiguration;
  },
  userDefaultMapLayersConfigs: state => {
    /*
      expected return value either null, or {
        Reference: true/false
        RejectStatus:true/false
        VisitStatus:true/false
        StructureType: true/false
      }
    */
    const userFilterConfig = state.UserFilterConfiguration;

    return userFilterConfig &&
      userFilterConfig != "No User Project Config Found" &&
      userFilterConfig[0].MapControlLayers
      ? userFilterConfig[0].MapControlLayers
      : null;
  },
  userMVAppliedLayersConfig: state => {
    const userFilterConfig = state.UserFilterConfiguration;

    return userFilterConfig &&
      userFilterConfig != "No User Project Config Found" &&
      userFilterConfig[0].MVAppliedLayersConfig
      ? userFilterConfig[0].MVAppliedLayersConfig
      : null;
  },
  projectConfigAndUserFilter: state => {
    return {
      userFilterConfiguration: Array.isArray(state.UserFilterConfiguration)
        ? state.UserFilterConfiguration[0]
        : state.UserFilterConfiguration,
      currentCustProjectConfig: state.CurrentCustProjectConfig,
      bothObjectsMatches:
        (state.CurrentCustProjectConfig &&
          state.UserFilterConfiguration == "No User Project Config Found") ||
        (state.UserFilterConfiguration &&
          state.CurrentCustProjectConfig &&
          state.UserFilterConfiguration[0].ProjectId ==
            state.CurrentCustProjectConfig.ProjectId)
    };
  },
  projectConfigAndUserFilterAndSearchConfig: state => {
    return {
      userFilterConfiguration: Array.isArray(state.UserFilterConfiguration)
        ? state.UserFilterConfiguration[0]
        : state.UserFilterConfiguration,
      currentCustProjectConfig: state.CurrentCustProjectConfig,
      currentProjectMVSearchConfig: state.ProjectMVSearchConfiguration,
      allObjectsMatch:
        (state.CurrentCustProjectConfig &&
          state.UserFilterConfiguration == "No User Project Config Found" &&
          state.ProjectMVSearchConfiguration) ||
        (state.UserFilterConfiguration &&
          state.CurrentCustProjectConfig &&
          state.ProjectMVSearchConfiguration &&
          state.UserFilterConfiguration[0].ProjectId ==
            state.CurrentCustProjectConfig.ProjectId &&
          state.CurrentCustProjectConfig.ProjectId ==
            state.ProjectMVSearchConfiguration[0].ProjectId)
    };
  },
  currentCustomerLogo: state => {
    if (
      state.CurrentCustProjectConfig &&
      state.CurrentCustProjectConfig.CustomerLogo &&
      state.CurrentCustProjectConfig.CustomerLogoBytes
    ) {
      const imgExtention =
        state.CurrentCustProjectConfig.CustomerLogo.split(".")[1];
      return {
        name: state.CurrentCustProjectConfig.CustomerLogo,
        imageSrc:
          "data:image/" +
          imgExtention +
          ";base64," +
          state.CurrentCustProjectConfig.CustomerLogoBytes
      };
    }
    return null;
  },
  currentProject: state => {
    return state.currentProject;
  },
  projects: state => {
    return state.projects;
  },
  getProjectInfo: state => projectId => {
    if (!projectId) return;
    for (const customer of state.projects) {
      for (const project of customer.projects) {
        if (project.projectId == projectId) {
          return project;
        }
      }
    }
    return;
  },
  getCustomerInfo: state => customerId => {
    if (!customerId) return;
    for (const customer of state.projects) {
      if (customer.customerId == customerId) {
        return customer;
      }
    }
    return;
  },
  globalCustomerProject: state => projectName => {
    for (let i = 0; i < state.projects.length; i++) {
      if (state.projects[i].customerName == "Global Customer") {
        const globalCustomerObj = state.projects[i];
        for (let j = 0; j < globalCustomerObj.projects.length; j++) {
          if (globalCustomerObj.projects[j].projectName == projectName) {
            return globalCustomerObj.projects[j];
          }
        }
      }
    }
    return null;
  },
  globalCustomerProjects: state => {
    for (let i = 0; i < state.projects.length; i++) {
      if (state.projects[i].customerName == "Global Customer") {
        return state.projects[i];
      }
    }
    return null;
  },
  globalCustomerHasApplication: state => applicationName => {
    for (let i = 0; i < state.projects.length; i++) {
      if (state.projects[i].customerName == "Global Customer") {
        const globalCustomerObj = state.projects[i];
        for (let j = 0; j < globalCustomerObj.projects.length; j++) {
          const tempApplications = globalCustomerObj.projects[j].applications;
          if (Array.isArray(tempApplications)) {
            for (let k = 0; k < tempApplications.length; k++) {
              if (tempApplications[k].applicationName == applicationName) {
                return tempApplications[k];
              }
            }
          }
        }
      }
    }
    return null;
  },
  projectsWithoutGlobal: state => {
    const projectsWithoutGlobal = [];
    for (let i = 0; i < state.projects.length; i++) {
      if (state.projects[i].customerId !== 1000000) {
        projectsWithoutGlobal.push(state.projects[i]);
      }
    }
    return projectsWithoutGlobal;
  },
  sortedCustomerList: (state, getters) => {
    let sortedProjectList = JSON.parse(
      JSON.stringify(getters.projectsWithoutGlobal)
    );
    if (sortedProjectList !== undefined && sortedProjectList !== null) {
      // sort customers
      sortedProjectList = sortedProjectList
        .slice()
        .sort((a, b) => a.customerName.localeCompare(b.customerName));

      // sort customers' projects
      for (let jj = 0; jj < sortedProjectList.length; jj++) {
        sortedProjectList[jj].projects = sortedProjectList[jj].projects
          .slice()
          .sort((a, b) => a.projectName.localeCompare(b.projectName));
      }
    }
    return sortedProjectList;
  },
  currentProjectId: state => {
    if (state.currentProject == undefined || state.currentProject == null) {
      return null;
    } else {
      return state.currentProject.projectId;
    }
  },
  currentProjectIdFromProjectConfig: state => {
    if (state.CurrentCustProjectConfig) {
      return state.CurrentCustProjectConfig.ProjectId;
    }
    return null;
  },
  currentCustomerId: state => {
    if (
      state.CurrentCustProjectConfig == undefined ||
      state.CurrentCustProjectConfig == null
    ) {
      return null;
    } else {
      return state.CurrentCustProjectConfig.CustomerId;
    }
  },
  currentCustomerIdFromCurrProj: state => {
    if (state.currentProject && state.currentProject.customerId) {
      return state.currentProject.customerId.toString();
    } else {
      return null;
    }
  },
  jobNumbers: state => {
    if (
      state.CurrentCustProjectConfig == undefined ||
      state.CurrentCustProjectConfig == null
    ) {
      return null;
    } else {
      return state.CurrentCustProjectConfig.JobIds.map(a => a.JobNumber).join(
        ","
      );
    }
  },
  jobNumbersLatestYear: (state, getters) => {
    if (
      state.CurrentCustProjectConfig == undefined ||
      state.CurrentCustProjectConfig == null
    ) {
      return null;
    } else {
      const contYear = getters.contractYear;
      return state.CurrentCustProjectConfig.JobIds.filter(
        a => a.ContractYear == contYear
      )
        .map(a => a.JobNumber)
        .join(",");
    }
  },
  jobNumbers2: (state, getters) => {
    return getters.userPreFilterfilteredJobNumbers
      .map(a => a.JobNumber)
      .join(",");
  },
  projectContractYears: state => {
    // returns a list of the unique contract years of the current project
    if (!state.CurrentCustProjectConfig) {
      return [];
    }
    const result = new Set();

    state.CurrentCustProjectConfig.JobIds.forEach(a => {
      result.add(a.ContractYear);
    });
    return Array.from(result).sort((a, b) => b - a);
  },
  projectServiceTypesByYear: state => years => {
    if (!years) return [];
    return (
      state?.CurrentCustProjectConfig?.JobIds.filter(j =>
        years.includes(j.ContractYear)
      )
        .map(j => j.ServiceType)
        .filter(k => state.MappingServiceToName[k]) // filter out servicetypes that are disabled
        .filter((value, i, self) => self.indexOf(value) === i) || [] // remove duplicates
    );
  },
  projectServiceTypes: state => {
    // returns a list of the unique service types of the current project
    if (!state.CurrentCustProjectConfig) {
      return [];
    }
    // filter here for service types as well
    let result = new Set();

    state.CurrentCustProjectConfig.JobIds.forEach(a => {
      result.add(a.ServiceType);
    });

    // filter out servicetypes that are disabled;
    result = Array.from(result).filter(k => state.MappingServiceToName[k]);
    return result;
  },
  // used in PD
  filteredServiceTypes: state => (years, serviceTypes) => {
    if (!state.CurrentCustProjectConfig || !years || !serviceTypes) {
      return null;
    }
    // filter here for service types as well
    const result = [];

    state.CurrentCustProjectConfig.JobIds.forEach(a => {
      for (let i = 0; i < serviceTypes.length; i++) {
        if (
          a.ServiceType == serviceTypes[i] &&
          result != undefined &&
          !result.includes(serviceTypes[i]) &&
          years != undefined &&
          years.includes(a.ContractYear)
        ) {
          result.push(serviceTypes[i]);
        }
      }
    });
    return result.join(",");
  },
  // used in PD
  filteredJobNumbers: state => serviceTypes => {
    // return a comma delimited list of jobNumbers that has one of the service types passed
    if (
      state.CurrentCustProjectConfig == undefined ||
      state.CurrentCustProjectConfig == null ||
      !serviceTypes
    ) {
      return null;
    } else {
      // filter here for service types as well
      const result = state.CurrentCustProjectConfig.JobIds.filter(a => {
        for (let i = 0; i < serviceTypes.length; i++) {
          if (a.ServiceType == serviceTypes[i]) {
            return true;
          }
        }
        return false;
      })
        .map(a => a.JobNumber)
        .join(",");
      return result;
    }
  },
  // used in PD
  filteredCustomerIds: state => serviceTypes => {
    if (
      state.CurrentCustProjectConfig == undefined ||
      state.CurrentCustProjectConfig == null
    ) {
      return null;
    } else {
      // filter here for service types as well
      const customerIds = state.CurrentCustProjectConfig.JobIds.filter(a => {
        for (let i = 0; i < serviceTypes.length; i++) {
          if (a.ServiceType == serviceTypes[i]) {
            return true;
          }
        }
        return false;
      }).map(a => a.CustomerId);
      const customerIdsWithoutDuplicates = new Set(customerIds);
      return Array.from(customerIdsWithoutDuplicates).join(",");
    }
  },
  contractYear: state => {
    // returns the latest contract year
    let contractYear = null;
    if (state.CurrentCustProjectConfig) {
      state.CurrentCustProjectConfig.JobIds.forEach(jobObj => {
        if (contractYear < jobObj.ContractYear) {
          contractYear = jobObj.ContractYear;
        }
      });
      return contractYear;
    }
    return contractYear;
  },
  latestContractYear: (state, rootGetters) => {
    const jobIdsArr = rootGetters.userPreFilterfilteredJobNumbers;
    let latestContractYear = new Set();
    for (const i in jobIdsArr) {
      latestContractYear.add(jobIdsArr[i].ContractYear);
    }
    latestContractYear = Array.from(latestContractYear).sort((a, b) => {
      return b < a ? -1 : 1;
    });

    return latestContractYear[0];
  },
  projectDashboardFilterAttributes: state => {
    if (!state.CurrentCustProjectConfig) {
      return null;
    } else {
      return state.CurrentCustProjectConfig.DashboardFilterAttributes;
    }
  },
  projectDashboardFilterAttributesHeaders: state => {
    if (!state.CurrentCustProjectConfig) {
      return "";
    } else {
      const filterCategories = Object.values(
        state.CurrentCustProjectConfig.DashboardFilterAttributes
      );
      const filterCategoriesList = filterCategories.map(
        item => item.ColumnName
      );
      return filterCategoriesList.join();
    }
  },
  mapViewFilterAttributes: state => {
    if (!state.CurrentCustProjectConfig) {
      return {};
    } else {
      return state.CurrentCustProjectConfig.MapViewFilterAttributes;
    }
  },
  rollupFilterAttributesHeaders: state => {
    if (!state.CurrentCustProjectConfig) {
      return "";
    } else {
      const filterCategories = Object.values(
        state.CurrentCustProjectConfig.RollUpFilterAttributes
      );
      const filterCategoriesList = filterCategories.map(
        item => item.ColumnName
      );
      return filterCategoriesList.join();
    }
  },
  userInfoURL: state => {
    return state.UserInfoURL;
  },
  defaultProjectUpdateURL: state => {
    return state.DefaultProjectUpdateURL;
  },
  userProjectsCount: state => {
    return state.projects.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.projects.length;
    }, 0);
  },
  hasCapability: state => (application, requestedCapability, project) => {
    let currentProjectCapabilities = [];
    /* if a project is passed in, then capability existance determenation would be based 
    on the passed in project. Otherwise it will be based on the current selected project. */
    const currentProjectApplications = project
      ? project.applications
      : state.currentProject
      ? state.currentProject.applications
      : null;
    if (Array.isArray(currentProjectApplications)) {
      for (let i = 0; i < currentProjectApplications.length; i++) {
        if (currentProjectApplications[i].applicationName == application) {
          currentProjectCapabilities =
            currentProjectApplications[i].capabilities;
          break;
        }
      }
      for (let j = 0; j < currentProjectCapabilities.length; j++) {
        if (
          currentProjectCapabilities[j].capabilityName == requestedCapability
        ) {
          return true;
        }
      }
    }
    return false;
  },
  projectType: state => {
    return state.currentProject ? state.currentProject.projectType : null;
  },
  hasProject: state => {
    return state.projects.length;
  },
  // isUserAuthorizedForApplication: state => (applicationName, capabilityName) => {
  //   let currentProjectApplications = state.currentProject.applications;
  //   for ( let i = 0; i < currentProjectApplications.length; i++ ) {
  //     if ( currentProjectApplications[i].applicationName == applicationName ) {
  //       let currentProjectAppCapabilities = currentProjectApplications[i].capabilities
  //       for ( let j = 0; j < currentProjectAppCapabilities.length; j++ ) {
  //         if ( currentProjectAppCapabilities[j].capabilityName == capabilityName ) {
  //           return true
  //         }
  //       }
  //     }
  //   }
  //   return false;
  // }

  allUserTypesURL: state => {
    return state.AllUserTypesURL;
  },
  allUserTypes: state => {
    return state.AllUserTypes;
  },
  userPreferencesURL: state => {
    return state.UserPreferencesURL;
  },
  userPreferences: state => {
    return state.UserPreferences;
  },
  visibility: state => {
    if (state.CurrentCustProjectConfig) {
      return state.CurrentCustProjectConfig.Visibility;
    }
    return null;
  },
  visibilityColumnDisplayName: (state, getters) => (TableName, columnName) => {
    return (
      getters.visibility?.Schema?.[TableName]?.[columnName]?.DisplayName ??
      getters.visibility?.Schema?.[TableName]?.[columnName]?.ColumnName
    );
  },
  visibilityColumnPath: (state, getters) => desiredColumnName => {
    /* exceptions */
    if (desiredColumnName == "VisitStatus")
      return "OUS_LOCATION.OUS_INSPECTION.O360InspectionState";
    else if (desiredColumnName == "InspectionAndTreatActivities")
      return "OUS_LOCATION.OUS_INSPECTION.OUS_POLECONDITION.InspectionAndTreatActivities";
    /* end exceptions */

    const visibilityObj = getters.visibility?.Schema;
    let returnValue;
    if (visibilityObj) {
      Object.keys(visibilityObj).forEach(objectName => {
        const table = visibilityObj[objectName];
        Object.keys(table).forEach(columnName => {
          const columnObj = table[columnName];
          if (columnObj["ColumnName"] == desiredColumnName) {
            returnValue = `${columnObj["TableName"]}.${columnObj["ColumnName"]}`;
          }
        });
      });
    }
    return returnValue;
  },
  appSpecialConfigurations: state => appName => {
    if (state.AppRegistry) {
      for (const i in state.AppRegistry) {
        if (state.AppRegistry[i].name == appName) {
          return state.AppRegistry[i].specialConfigurations;
        }
      }
    }
    return null;
  },
  appRegistryByName: state => (appName, omitCase) => {
    if (Array.isArray(state.AppRegistry)) {
      for (const index in state.AppRegistry) {
        if (!omitCase && state.AppRegistry[index].name == appName) {
          return state.AppRegistry[index];
        } else if (
          omitCase &&
          state.AppRegistry[index].name?.toLowerCase() == appName?.toLowerCase()
        ) {
          return state.AppRegistry[index];
        }
      }
    }
    return {};
  },
  applicationAlias: state => appName => {
    if (Array.isArray(state.AppRegistry)) {
      for (const index in state.AppRegistry) {
        if (state.AppRegistry[index].name == appName) {
          return state.AppRegistry[index].alias;
        }
      }
    }
    return null;
  },
  getServiceTypeArr: state => {
    return state.ServiceTypeArr;
  },
  showUserChangelog: state => {
    return state.showUserChangelog;
  },
  reShowChangelog: state => {
    return state.reShowChangelog;
  },
  dbdContact: state => {
    return state.dbdContact;
  },
  /*
    parse month-day out of projectConfig fiscalYear dates
    return: {start: <month-day>, end: <month-day}
  */
  fiscalYearStartEnd: state => {
    const fiscalYearStart =
      state.CurrentCustProjectConfig &&
      state.CurrentCustProjectConfig.FiscalYearStartDate
        ? new Date(state.CurrentCustProjectConfig.FiscalYearStartDate)
        : null;
    const fiscalYearEnd =
      state.CurrentCustProjectConfig &&
      state.CurrentCustProjectConfig.FiscalYearEndDate
        ? new Date(state.CurrentCustProjectConfig.FiscalYearEndDate)
        : null;

    const fiscalYearStartEndObj =
      state.CurrentCustProjectConfig && fiscalYearStart && fiscalYearEnd
        ? {
            start: `${
              fiscalYearStart.getMonth() + 1
            }-${fiscalYearStart.getDate()}`,
            end: `${fiscalYearEnd.getMonth() + 1}-${fiscalYearEnd.getDate()}`
          }
        : {};
    return fiscalYearStartEndObj;
  },
  calculateFiscalYearStartEnd:
    (state, getters) => (startContractYear, endContractYear) => {
      const projectConfig = getters.fiscalYearStartEnd;
      let startWeekendingDate = new Date(
        `${startContractYear}-${projectConfig.start}`
      );
      let endWeekendingDate = new Date(
        `${endContractYear}-${projectConfig.end}`
      );

      /* 
      This is a 4/4/5 fiscal year start/end calculation.
      Check https://en.wikipedia.org/wiki/Accounting_period "Saturday nearest the end of month"
      for more details
    */
      if (startWeekendingDate.getDay() <= 3) {
        startWeekendingDate.setDate(
          startWeekendingDate.getDate() - startWeekendingDate.getDay()
        );
      } else {
        startWeekendingDate.setDate(
          startWeekendingDate.getDate() + (7 - startWeekendingDate.getDay())
        );
      }
      if (endWeekendingDate.getDay() <= 2) {
        endWeekendingDate.setDate(
          endWeekendingDate.getDate() - (endWeekendingDate.getDay() + 1)
        );
      } else {
        endWeekendingDate.setDate(
          endWeekendingDate.getDate() + (6 - endWeekendingDate.getDay())
        );
      }

      startWeekendingDate = getFormattedDate(startWeekendingDate);
      endWeekendingDate = getFormattedDate(endWeekendingDate);
      return `${startWeekendingDate}:${endWeekendingDate}`;
    },
  fiscalYearsOptions: (state, getters) => {
    if (
      getters.fiscalYearStartEnd &&
      Object.keys(getters.fiscalYearStartEnd).length &&
      getters.fiscalYearStartEnd.end != "12-31"
    ) {
      const fiscalYearOptions = new Set();
      for (const contractYear of getters.projectContractYears) {
        const currentDate = new Date();
        const fiscalYearStartMonth = new Date(
          getters.currentCustProjectConfig.FiscalYearStartDate
        ).getMonth();

        const yearPrevToContractYear = -1 + +contractYear;
        const yeafAfterContractYear = 1 + +contractYear;
        // don't add fiscal year option that didn't start yet
        if (new Date(contractYear, fiscalYearStartMonth) < currentDate)
          fiscalYearOptions.add(`${contractYear},${yeafAfterContractYear}`);
        if (
          new Date(yearPrevToContractYear, fiscalYearStartMonth) < currentDate
        )
          fiscalYearOptions.add(`${yearPrevToContractYear},${contractYear}`);
      }

      return Array.from(fiscalYearOptions);
    } else {
      return [];
    }
  },
  statesDict: state => {
    return state.StatesMapping;
  },
  statesDictArray: state => {
    return Object.values(state.StatesMapping);
  },
  stateName: state => Abbriviation => {
    return state.StatesMapping[Abbriviation];
  },
  mvProjectPoleCount: (state, rootGetters) => {
    return rootGetters.mvProjectPoleCountInsideProjectStore;
  },
  filterLayerQueryResultsInsideAuthStore: (state, rootGetters) => {
    return rootGetters.filterLayerQueryResults;
  },
  maintenanceUpdatesEnabled: state => {
    return state.CurrentCustProjectConfig
      ? state.CurrentCustProjectConfig.EnableMaintenanceUpdates
      : null;
  },
  showMaintenanceNeededTab: (state, getters) => {
    const projectLevel =
      getters.currentCustProjectConfig?.EnableMaintenanceUpdates;
    const customerLevel = getters.hasCapability(
      "MapView",
      "Update Maintenance Records"
    );
    return projectLevel && customerLevel;
  },
  showSolutionApprovalTab: (state, getters) => {
    const projectLevel =
      getters.currentCustProjectConfig?.EnableResiliancyFeatures;
    const customerLevel = getters.hasCapability("MapView", "Truss Approval");
    return projectLevel && customerLevel;
  },
  historicalDataExpiryDate: (state, getters) => {
    const historicalYearConfigs =
      getters.currentCustProjectConfig?.HistoricalYearsConfig;
    const expirydate = historicalYearConfigs?.[0]?.ExpiryDate;
    return getFormattedDateForHistorical(new Date(expirydate));
  },
  historicalDataEnabled: (state, getters) => {
    const historicalYearConfigs =
      getters.currentCustProjectConfig?.HistoricalYearsConfig;
    const enabled = historicalYearConfigs?.[0]?.isEnabled;
    const bannerDays = historicalYearConfigs?.[0]?.BannerDisplayDays;
    const expirydate = new Date(historicalYearConfigs?.[0]?.ExpiryDate);
    const expirystartdate = new Date(historicalYearConfigs?.[0]?.ExpiryDate);
    if (enabled) {
      const today = new Date();
      expirystartdate.setDate(expirydate.getDate() - bannerDays);

      if (expirystartdate <= today && today <= expirydate) return true;
      else return false;
    } else return false;
  },
  historicalYearValid: (state, getters) => {
    return getters.currentCustProjectConfig?.HistoricalValidYears;
  },
  showResiliencyOptions: (state, getters) => {
    const projectLevel =
      getters.currentCustProjectConfig?.EnableResiliancyFeatures;
    const customerLevel = getters.hasCapability("MapView", "View Resiliency");
    return projectLevel && customerLevel;
  },
  CurrentAdvancedQuery: state => {
    return state.CurrentAdvancedQuery;
  },
  currentMVUserFilter: state => {
    return state.CurrentMVUserFilterQuery;
  },
  filtersForJobCodes: (state, getters) => querySpec => {
    const child1Group = {
      FilterType: "Parent",
      Conjunction: "AND",
      Filters: []
    };

    const jobNumbersFilter = {
      FilterType: "Multiple",
      Field: "OUS_LOCATION.OUS_INSPECTION.JobNumber",
      Type: "string",
      Operator: "equal",
      Values:
        // Sometimes we want to use all jobNumbers in the project such as in distinctValues queries
        // So if jobNumbers are already defined in the querySpec use that instead
        querySpec.jobNumbers?.split(",") ??
        getters.filteredJobNumbers5([], []).split(",")
    };

    child1Group.Filters.push(jobNumbersFilter);

    const parentGroup = {
      FilterType: "Parent",
      Conjunction: "AND",
      Filters: []
    };

    if (child1Group.Filters.length) parentGroup.Filters.push(child1Group);
    querySpec.Filters.push(parentGroup);

    return querySpec;
  }
};
export const actions = {
  updateMSALObj({ commit }, mSALObj) {
    commit("SET_MY_MSAL_OBJ", mSALObj);
  },
  updateAuthAccountObj({ commit }, newAuthAccountObj) {
    commit("SET_AUTH_ACCOUNT_OBJ", newAuthAccountObj);
  },
  updateO360APIScope({ commit }, o360APIScope) {
    commit("SET_O360API_SCOPE", o360APIScope);
  },
  updateClientId({ commit }, clientId) {
    commit("SET_CLIENT_ID", clientId);
  },
  updateAuthority({ commit }, authority) {
    commit("SET_AUTHORITY", authority);
  },
  updateAuthToken({ state, commit, dispatch }, response) {
    commit("SET_AUTHTOKEN", response);
    console.log("authToken: ", response.accessToken);
    if (response.accessToken && !response.notFirstTime)
      dispatch("getApplicationRegistry", state);
    if (response.accessToken && !response.notFirstTime) dispatch("getUserInfo");
    if (response.accessToken && !response.notFirstTime)
      dispatch("getServiceTypeArr");
  },
  updateOnStartAppState({ state, commit, dispatch }, onStartAppState) {
    commit("SET_ON_START_APP_STATE", onStartAppState);
  },
  updateOnStartAppRoute({ state, commit, dispatch }, onStartAppRoute) {
    commit("SET_ON_START_APP_ROUTE", onStartAppRoute);
  },
  updateUserName({ commit }, userName) {
    commit("SET_USERNAME", userName);
  },
  updateUserId({ commit }, userId) {
    commit("SET_USERID", userId);
  },
  updateUserEmail({ commit, dispatch }, userEmail) {
    commit("SET_USEREMAIL", userEmail);
  },
  updateUserFName({ commit }, userFName) {
    commit("SET_USERFNAME", userFName);
  },
  updateUserLName({ commit }, userLName) {
    commit("SET_USERLNAME", userLName);
  },
  updateUserCity({ commit }, userCity) {
    commit("SET_USERCITY", userCity);
  },
  updateUserState({ commit }, userState) {
    commit("SET_USERSTATE", userState);
  },
  updateUserAddress({ commit }, userAddress) {
    commit("SET_USERADDRESS", userAddress);
  },
  updateUserZip({ commit }, userZip) {
    commit("SET_USERZIP", userZip);
  },
  updateAppRegistry({ state, commit, dispatch }, appRegistry) {
    commit("SET_APP_REGISTRY", appRegistry);
    dispatch("getAuthObjectsForUser", state.UserId);
  },
  updateAppRegistryURL({ commit }, appRegistryURL) {
    commit("SET_APP_REGISTRY_URL", appRegistryURL);
  },
  updateUserAuthObject({ state, commit, dispatch }, userAuthObject) {
    // console.dir(userAuthObject);
    commit("SET_USER_AUTH_OBJECT", userAuthObject);
    dispatch(
      "updateShowUserChangelog",
      userAuthObject.showReleaseNotificationPopUp
    );
    if (userAuthObject.customers.length) {
      authorizationManager.createAuthInfo(
        state.UserAuthObject,
        state.AppRegistry,
        dispatch,
        "updateAuthInfo"
      );
    }
  },
  updateUserFilterConfiguration({ commit, dispatch }, userFilterConfiguration) {
    commit("SET_USER_FILTER_CONFIGURATION", userFilterConfiguration);

    if (userFilterConfiguration?.[0]?.Visibilities) {
      dispatch("setUserVisibilities", userFilterConfiguration[0].Visibilities);

      // When userFilterConfiguration is being reset, reset user's visibilites as well
    } else if (userFilterConfiguration == null) {
      dispatch("setUserVisibilities", null);
    }
  },
  updateProjectMVSearchConfiguration({ commit }, projectMVSearchConfiguration) {
    commit("SET_PROJECT_MV_SEARCH_CONFIGURATION", projectMVSearchConfiguration);
  },
  updateAuthInfo({ dispatch }, authInfo) {
    // console.dir(authInfo);
    // console.dir(commit);
    dispatch("updateUserApplications", authInfo.UserApplications);
    dispatch("updateAppCababilities", authInfo.AppCapabilities);
    dispatch("updateProjects", authInfo.Projects);
    dispatch("updateHasUserTakenTour", authInfo.HasUserTakenTour);
    dispatch("updateCurrentProjToDefaultProj", authInfo.Projects);
  },
  updateJUCapability({ dispatch, rootGetters }, currentCustProjectConfig) {
    const userObj = rootGetters.userAuthObject;
    const Capabilities = rootGetters.allCapabilities;
    let currentCustomer;
    for (const customer of userObj.customers) {
      if (customer.customerId == currentCustProjectConfig.CustomerId) {
        currentCustomer = customer;
      }
    }
    const capabilities = {
      JointUse: null
    };
    for (const project of currentCustomer.projects) {
      if (project.projectId == currentCustProjectConfig.ProjectId) {
        const application = project.applications.find(
          a => a.applicationName == "JointUse"
        );
        capabilities.JointUse = application ? application.capabilities : [];
      }
    }
    dispatch("updateAppCababilities", { ...Capabilities, ...capabilities });
  },
  clearJUCapabilities({ dispatch, rootGetters }) {
    const Capabilities = rootGetters.allCapabilities;
    dispatch("updateAppCababilities", { ...Capabilities, JointUse: null });
  },
  updateUserAuthObjectURL({ commit }, userAuthObjectURL) {
    commit("SET_USER_AUTH_OBJECT_URL", userAuthObjectURL);
  },
  updateCustomerProjectURL({ state, commit }, customerProjectURL) {
    commit("SET_CUSTOMER_PROJECT_URL", customerProjectURL);
  },
  updateUserApplications({ commit }, userApplications) {
    commit("SET_USER_APPLICATIONS", userApplications);
  },
  updateAppCababilities({ commit }, appCapabilities) {
    commit("SET_APP_CAPABILITIES", appCapabilities);
  },
  getAuthObjectsForUser({ state, commit, dispatch }, userId) {
    authorizationManager.getConfiguration(
      state.UserAuthObjURL,
      state.AuthToken,
      dispatch,
      "updateUserAuthObject",
      commit
    );
  },

  getApplicationRegistry({ state, commit, dispatch }) {
    authorizationManager.getConfiguration(
      state.AppRegistryURL,
      state.AuthToken,
      dispatch,
      "updateAppRegistry",
      commit
    );
  },

  getAllUserTypes({ state, commit, dispatch }) {
    authorizationManager.getConfiguration(
      state.AllUserTypesURL,
      state.AuthToken,
      dispatch,
      "updateAllUserTypes",
      commit
    );
  },
  getUserPreferences({ state, commit, dispatch }) {
    // console.log(state.AuthToken);
    authorizationManager.getConfiguration(
      state.UserPreferencesURL,
      state.AuthToken,
      dispatch,
      "updateUserPreferences",
      commit
    );
  },

  updateFindIfUserExistURL({ commit }, findIfUserExistURL) {
    commit("SET_FIND_IF_USER_EXIST_URL", findIfUserExistURL);
  },
  updateAddNewUserURL({ commit }, addNewUserURL) {
    commit("SET_ADD_NEW_USER_URL", addNewUserURL);
  },
  updateCurrentCustProjectConfig({ state, commit, dispatch }, project) {
    if (project) console.log("new o360ProjectDO has been recieved.");
    console.log("in updatecurrentCustProjectConfig project");
    commit("SET_CURRENT_CUST_PROJECT_CONFIG", project);
  },
  updateCurrentProject({ state, commit, dispatch }, project) {
    console.log("in updatecurrent project");
    commit("SET_CURRENT_PROJECT", project);
    if (project && project.projectId) {
      dispatch("getCustomerProjectInfo", project.projectId);
    }

    // let the logger know that currentProject exist now
    dispatch("updateReadyToCreateLoggedInLog", { currentProjectExist: true });
  },
  updateProjects({ state, commit }, projects) {
    console.log("in updatecurrent projects");
    console.dir(projects);
    commit("SET_PROJECTS", projects);
  },
  retakeTour({ state, commit }) {
    commit("SET_RETAKE_TOUR");
  },
  updateHasUserTakenTour({ state, commit }, hasTakenTour) {
    commit("SET_HAS_USER_TAKEN_TOUR", hasTakenTour);
  },
  updateCurrentProjectById({ state, commit, dispatch }, projectId) {
    console.log("in updatecurrent projectbyid");

    let project;
    for (let x = 0; x < state.projects.length; x++) {
      for (let y = 0; y < state.projects[x].projects.length; y++)
        if (state.projects[x].projects[y].projectId == projectId) {
          project = state.projects[x].projects[y];
          project.customerId = state.projects[x].customerId;
          break;
        }
    }

    commit("SET_CURRENT_PROJECT", project);
    dispatch("updateCurrentCustProjectConfig", null);
    dispatch("getCustomerProjectInfo", projectId);
    dispatch("updateUserDefaultProject", projectId);
    //dispatch("clearJUCapabilities");
  },
  updateCurrentProjToDefaultProj({ state, commit, dispatch }, projects) {
    let defaultProject = null;
    outerloop: for (const x in projects) {
      const customer = projects[x];
      for (const y in customer.projects) {
        const project = customer.projects[y];
        if (project.isDefault) {
          defaultProject = project;
          defaultProject.customerId = customer.customerId;
          break outerloop;
        }
      }
    }
    dispatch("updateCurrentProject", defaultProject);
  },
  getUserInfo({ state, commit, dispatch, getters }) {
    if (getters.isCustomer) {
      authorizationManager.getConfiguration(
        state.UserInfoURL,
        state.AuthToken,
        dispatch,
        "updateUserInfo",
        commit
      );
    }
  },
  updateUserInfo({ state, commit, dispatch }, userInfo) {
    commit("SET_USER_INFO", userInfo);

    // let the logger know that userInfo exist now
    dispatch("updateReadyToCreateLoggedInLog", { userInfoExist: true });
  },
  updateUserInfoURL({ state, commit }, userInfoURL) {
    commit("SET_USER_INFO_URL", userInfoURL);
  },

  updateAllUserTypesURL({ state, commit }, allUserTypesURL) {
    commit("SET_ALL_USER_TYPES_URL", allUserTypesURL);
  },
  updateAllUserTypes({ state, commit }, allUserTypes) {
    commit("SET_ALL_USER_TYPES", allUserTypes);
  },
  updateUserPreferencesURL({ state, commit }, userPreferencesURL) {
    commit("SET_USER_PREFERENCES_URL", userPreferencesURL);
  },
  updateUserPreferences({ state, commit }, userPreferences) {
    commit("SET_USER_PREFERENCES", userPreferences);
  },

  getCustomerProjectInfo({ state, commit, dispatch }, currentProjectId) {
    configurationManager.getO360tProjectsObj(
      currentProjectId,
      state.AuthToken,
      dispatch,
      "updateCurrentCustProjectConfig",
      state.CustomerProjectURL
    );
  },
  updateDefaultProjectUpdateURL({ state, commit, dispatch }, url) {
    commit("SET_DEFAULT_PROJECCT_UPDATE_URL", url);
  },
  updateUserDefaultProject({ state, commit, dispatch }, projectId) {
    configurationManager.updateUserDefaultProject(
      state.DefaultProjectUpdateURL,
      state.AuthToken,
      state.UserId,
      projectId
    );
  },
  getServiceTypeArr({ state, commit, dispatch, rootGetters }, value) {
    authorizationManager.getServiceTypeData(
      rootGetters.authToken,
      dispatch,
      "updateAllServiceTypeArr"
    );
  },
  updateAllServiceTypeArr({ state, commit, dispatch }, value) {
    commit("SET_SERVICE_TYPE_ARR", value);
    dispatch("mappingServiceToName", value);
  },
  mappingServiceToName({ state, commit, rootGetters }, value) {
    const allServiceArr = value;
    const mappingObj = {};
    allServiceArr.forEach(obj => {
      mappingObj[obj.serviceTypeCode] = obj.displayName;
    });
    commit("SET_MAPPING_SERVICE_TO_NAME", mappingObj);
  },
  updateShowUserChangelog({ state, commit }, value) {
    commit("SET_SHOW_USER_CHANGELOG", value);
  },
  updateReShowChangelog({ state, commit }, value) {
    commit("SET_RE_SHOW_CHANGELOG", value);
  },
  getDBDContactDetails({ state, commit }, value) {
    commit("SET_DBD_CONTACT", value);
  },
  updateStatesMapping({ state, commit }, data) {
    const stateDict = Object.fromEntries(
      data && Array.isArray(data) ? data.map(a => [a.StateId, a.StateName]) : []
    );
    commit("SET_STATES_MAPPING", stateDict);
  },
  setAdvancedSearchValidity({ commit }, payload) {
    commit("SET_ADVANCED_SEARCH_VALIDITY", payload);
  },
  setCurrentAdvancedQuery({ commit }, params) {
    commit("SET_CURRENT_ADVANCED_QUERY", params);
  },
  // fetches unique values for an attribute in the visibility for use in Advanced Search
  fetchVisibilityAttributeValue2(
    { dispatch, getters, rootState, rootGetters },
    payload
  ) {
    const {
      TableName,
      ColumnName,
      ReferencedColumn,
      ReferencedTable,
      SQLDataType,
      DataType
    } = payload.attribute;

    console.log(
      "in authorization  store to get attribute values for advanced search"
    );

    let querySpec = {};
    querySpec.Format = "JSON";
    querySpec.Conjunction = "OR";
    querySpec.Filters = [];

    querySpec.visitStatuses = [
      "Out for Collection",
      "Prefield",
      "Preliminary",
      "Delivered"
    ];
    querySpec.jobNumbers = getters.jobNumbers2;

    // if the applyFilterQuery is available use it(MapView)
    if (getters.applyFilterQuery) {
      console.log("applyFilterQuery");
      getters.applyFilterQuery(querySpec);
    } else {
      querySpec = getters.filtersForJobCodes(querySpec);
    }

    querySpec.CustomerId = rootGetters.currentCustomerId;
    querySpec.Visibility = {
      Schema: {
        [formatedTableName(TableName)]: {
          [ColumnName]: {
            TableName: TableName,
            ColumnName: ColumnName,
            SQLDataType: SQLDataType, //"string",
            ReferencedColumn: ReferencedColumn, //null, //
            ReferencedTable: ReferencedTable, //null, //
            DataType: DataType
          }
        }
      }
    };
    querySpec.GroupByFields = [`${TableName}.${ColumnName}`];

    querySpec.AuthToken = rootState.authorizationStore.AuthToken;
    const queryServiceURL = process.env.VUE_APP_QueryServiceURL;
    querySpec.queryName = "get" + ColumnName;
    querySpec.errorMessage = `Could not retrieve filter configurations. Please try again. 
          If this error continues to occur, please contact support.`;
    CosmosQueryEngine.executeQueryFromSpec(
      querySpec,
      queryServiceURL,
      "updateVisibilityValue",
      dispatch,
      {
        Name: `${TableName}.${ColumnName}`
      }
    );
  },

  // fetches unique values for an attribute in the visibility for use in Advanced Search
  // async fetchVisibilityAttributeValue(
  //   { dispatch, getters, rootState, rootGetters },
  //   payload
  // ) {
  //   const {
  //     TableName,
  //     ColumnName,
  //     ReferencedColumn,
  //     ReferencedTable,
  //     SQLDataType,
  //     DataType
  //   } = payload.attribute;

  //   console.log(
  //     "in authorization  store to get attribute values for advanced search"
  //   );

  //   const querySpec = payloadManager.getParentRule("OR");
  //   querySpec.Format = "JSON";

  //   const visitStatuses = [
  //     "Out for Collection",
  //     "Prefield",
  //     "Preliminary",
  //     "Delivered"
  //   ];

  //   const visitStatusRules = payloadManager.getRuleForSingleAndMultipleValues({
  //     field: "OUS_LOCATION.OUS_INSPECTION.VisitStatus",
  //     type: "string",
  //     operator: "equal",
  //     /*
  //       If jobNumbers defined in the query spec use it (distinct queries have that). Else if date range filter is selected,
  //       use jobNumbers that adheres to selected service types. Else, use jobNumbers that adhere to years and service types selected
  //       in the filter flyout
  //     */
  //     values: visitStatuses
  //   });

  //   querySpec.Rules.push(visitStatusRules);

  //   // console.log("job numbers 2");
  //   // console.log(getters.jobNumbers2);
  //   // const jobNumberRules = payloadManager.getRuleForSingleAndMultipleValues({
  //   //   field: "OUS_LOCATION.OUS_INSPECTION.JobNumber",
  //   //   type: "string",
  //   //   operator: "equal",
  //   //   /*
  //   //         If jobNumbers defined in the query spec use it (distinct queries have that). Else if date range filter is selected,
  //   //         use jobNumbers that adheres to selected service types. Else, use jobNumbers that adhere to years and service types selected
  //   //         in the filter flyout
  //   //       */
  //   //   values: getters.jobNumbers2.split(",")
  //   // });

  //   // querySpec.Rules.push(jobNumberRules);

  //   // what does this do?
  //   // if the applyFilterQuery is available use it(MapView)
  //   // if (getters.applyFilterQuery) {
  //   //   console.log("applyFilterQuery");
  //   //   getters.applyFilterQuery(querySpec);
  //   // } else {
  //   //   querySpec = getters.filtersForJobCodes(querySpec);
  //   // }

  //   const options = { queryFormat: "TABLE_VIEW" };
  //   const o360StoreHelper = new O360StoreHelper();
  //   const configs = o360StoreHelper.getConfigurationsProxy();
  //   const filterRules = await O360MapViewPayloadManager.getFilterConfiguration(
  //     configs,
  //     options
  //   );

  //   querySpec.Rules.push(filterRules);

  //   querySpec.CustomerId = rootGetters.currentCustomerId;
  //   querySpec.Visibility = {
  //     Schema: {
  //       [formatedTableName(TableName)]: {
  //         [ColumnName]: {
  //           TableName: TableName,
  //           ColumnName: ColumnName,
  //           SQLDataType: SQLDataType, //"string",
  //           ReferencedColumn: ReferencedColumn, //null, //
  //           ReferencedTable: ReferencedTable, //null, //
  //           DataType: DataType
  //         }
  //       }
  //     }
  //   };
  //   querySpec.GroupByFields = [`${TableName}.${ColumnName}`];

  //   querySpec.queryName = "get" + ColumnName;
  //   querySpec.errorMessage = `Could not retrieve filter configurations. Please try again.
  //       If this error continues to occur, please contact support.`;
  //   querySpec.ColumnList = [
  //     {
  //       Field: `${TableName}.${ColumnName}`
  //     }
  //   ];

  //   //const o360CosmosDataSource = new O360CosmosDataSource();
  //   const o360CosmosDataSource = new O360MapControlDataSource();
  //   const o360AdvancedSearchClient = new O360AdvancedSearchClient();
  //   //const o360AdvancedSearchClient = new O360MapControlDataSource();
  //   o360AdvancedSearchClient.dispatch = dispatch;
  //   o360AdvancedSearchClient.callBack = "updateVisibilityValue";
  //   o360AdvancedSearchClient.params = {
  //     Name: `${TableName}.${ColumnName}`
  //   };
  //   o360CosmosDataSource.registerClient(o360AdvancedSearchClient);
  //   o360CosmosDataSource.payload = querySpec;
  //   o360CosmosDataSource.projectId = this.getters.currentProjectId;
  //   // this needs to do what group by data does, override the execute method
  //   await o360CosmosDataSource.initData();
  // },

  setCurrentMVUserFilterQuery({ commit }, payload) {
    commit("SET_MVUser_FILTER_QUERY", payload);
  },
  setMVUserFilterHasChanges({ commit }, payload) {
    commit("SET_MVUser_Filter_HAS_CHANGES", payload);
  },
  updateVisibilityValue({ commit }, params) {
    console.log("updateVisibilityValue");
    console.log(params);
    const MAX_NUMBER = 500;
    commit("SET_ATTR_VALUE", {
      name: params.additonalParams.Name,
      maxExceeded: params.results.length > MAX_NUMBER ? true : false,
      values: params.results
        .flatMap(r => Object.values(r).filter(r => r))
        .sort((a, b) => (a < b ? -1 : a > b ? 1 : 0))
        .slice(0, MAX_NUMBER)
    });
  },
  clearQueryAttributes({ commit }) {
    commit("CLEAR_ATTR_VALUE");
  }
};

/*******************************
 * Helper methods
 ********************************/
function getFormattedDate(date) {
  const year = date.getFullYear();

  let month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : "0" + month;

  let day = date.getDate().toString();
  day = day.length > 1 ? day : "0" + day;

  return month + "-" + day + "-" + year;
}
function getFormattedDateForHistorical(date) {
  const year = date.getFullYear();

  let month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : "0" + month;

  let day = date.getDate().toString();
  day = day.length > 1 ? day : "0" + day;

  return month + "/" + day + "/" + year;
}

function formatedTableName(tableName) {
  if (tableName.includes(".")) {
    const tempArr = tableName.split(".");
    return tempArr[tempArr.length - 1];
  } else {
    return tableName;
  }
}

export const convertToInputType = (dataType, sqlDataType) => {
  const type = sqlDataType?.includes("date") ? "date" : dataType;

  switch (type.toUpperCase()) {
    case "STRING":
      return "text";
    case "DOUBLE":
    case "INTEGER":
      return "number";
    case "DATE":
      return "date";
    case "BOOLEAN":
      return "radio";
    default:
      return "text";
  }
};

const getDisplayName = (tableName, columnName, displayName = null) => {
  return `${
    tableName.substring(tableName.lastIndexOf(".") + 1).split("_")[1]
  }.${displayName || columnName}`;
};

const getConditionsByType = type => {
  type = type || "";

  switch (type.toUpperCase()) {
    case "TEXT":
      return STRING_OPERATORS;
    case "DATE":
      return DATE_OPERATORS;
    case "NUMBER":
      return INT_OPERATORS;
    default:
      return [];
  }
};

const STRING_OPERATORS = [
  "Equals",
  "Does not equal",
  "Contains",
  "Does not contain",
  "Begins with",
  "Is empty",
  "Is not empty"
];
const DATE_OPERATORS = [
  "Equals",
  "After",
  "After or on",
  "Before",
  "Before or on"
];
const INT_OPERATORS = [
  "Equals",
  "Greater than",
  "Greater than or equals",
  "Less than",
  "Less than or equals"
];
